import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageCarousel.css';
import LazyLoad from 'react-lazyload';
import ButtonPage from '../ButtonPage/ButtonPage';

const images = [
  '/Image/t1.png', 
  '/Image/t2.png', 
  '/Image/t3.png', 
  '/Image/t4.png', 
  '/Image/t5.png', 
  '/Image/t6.png', 
  '/Image/t7.png', 
  '/Image/t8.png', 
];

const videos = [
  { url: "https://www.youtube.com/embed/QTKzzxt6ALA" },
  { url: "https://www.youtube.com/embed/oRkhLN34TL0" },
  { url: "https://www.youtube.com/embed/IYCsJBmsY4E" },
  { url: "https://www.youtube.com/embed/desrP6gIIWQ" },
];

const ImageCarousel = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const settings = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 3,
    focusOnSelect: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
    dots: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        }
      }
    ]
  };

  const handleVideoEnd = () => {
    if (currentVideoIndex < videos.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    } else {
      setCurrentVideoIndex(0);
    }
  };

  return (
    <div className='carousel-container-main'>
      <div className="carousel-container">
        <h1 className='cch1'> Testimonials</h1>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="carousel-slide">
              <img src={image} alt={`Slide ${index + 1}`} className="carousel-image" />
            </div>
          ))}
        </Slider>
      </div>
<ButtonPage/>
      <div className="video-gallery">
        {videos.map((video, index) => (
          <LazyLoad key={index} height={200} offset={100} once>
            <div className="video-container">
              <iframe
                src={video.url}
                title={`Video ${index + 1}`}
                width="100%"
                height="auto"
                className="video"
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe>
            </div>
          </LazyLoad>
        ))}
      </div>
<ButtonPage/>
      
    </div>
  );
};

export default ImageCarousel;
