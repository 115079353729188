import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import "./AppreciationPage.css";

function AppreciationPage() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="app-container">
      <h1 className="apprih">Appreciated By</h1>
      <div className="imageContainer">
        <div className="imageWrapper" data-aos="fade-up">
          <img src="/Image/ankur.png" alt="Ankur" className="image" />
          <p className="name">Ankur warikoo</p>
        </div>
        <div className="imageWrapper" data-aos="fade-up">
          <img src="/Image/ashis.png" alt="Ashis" className="image" />
          <p className="name">Ashish Chanchlani</p>
        </div>
      </div>
    </div>
  );
}

export default AppreciationPage;
