import React, { useState } from 'react';
import './FAQItem.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`item ${isOpen ? 'open' : ''}`} onClick={toggleOpen}>
      <div className="title font-bold flex justify-between items-center">
        <span className="text-lg md:text-xl">{question}</span>
        <div className={`icon-wrapper ${isOpen ? 'rotate' : ''}`}>
          {isOpen ? <FaChevronUp className="icon" /> : <FaChevronDown className="icon" />}
        </div>
      </div>
      <p className={`text ${isOpen ? 'show' : ''}`}>{answer}</p>
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    { question: 'Who can enroll into this program?', answer: 'If you are tired of following different diets and trends, not able to get fit, still fail after repeated tries- this is the right place for you!' },
    { question: 'Would a workout plan be included in the program?', answer: 'Yes. You will be provided with a personalized workout routine.' },
    { question: 'How much weight loss can be expected?', answer: 'Weight is a highly variable factor. Keeping the principle of individuality in mind- we cannot assure a fixed number, but we do assure that you will be completely satisfied with the result regardless.' },
    { question: 'Is there any 1-month program available?', answer: 'No, we do not offer 1 or 2-month plans. Results take time.' },
    { question: 'What are the payment options available?', answer: 'You can pay via any method of your preference! UPI apps, credit card, debit card, etc. are all available.' },
    { question: 'Is there any refund policy?', answer: 'The payment made by you is neither refundable nor transferable.' },
  ];

  return (
    <div className="container p-4 md:p-8 mx-auto text-zinc-700 flex-col divide-y-2 divide-solid divide-white divide-opacity-5">
      <h1 className="text-3xl font-bold text-center mb-2">FAQ</h1>
      <p className="text-center text-lg text-gray-600 mb-6">Frequently Asked Questions</p>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQ;
