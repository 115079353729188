import React from 'react';
import './LogoMarqueeSection.css';

const LogoMarqueeSection = () => {
  const logos = [
    "/Image/redfm.png",
    "/Image/cnn.png",
    "/Image/news18.png",
    "/Image/isqfm.webp",
  ];

  return (
    <section className="logoGridSection">
      <div className="container">
        <h1 className="text-3xl font-bold text-center mb-2">Featured In</h1>
        <div className="marquee">
          <div className="marquee-content">
            {logos.concat(logos).map((logo, index) => (
              <img key={index} src={logo} alt={`Logo ${index}`} className="grid-logo" />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogoMarqueeSection;
