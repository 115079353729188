
import './App.css';
import Home from './Components/Home/Home';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import AdminPage from './Components/AdminPage/AdminPage';
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: true, 
      easing: 'ease-out',
    });
  }, []);
  return (
    <div className="App">
    
      <Routes>
    <Route exact path="/" element={<Home />} />
      
      <Route exact path="/admingfvfsdr" element={<AdminPage/>} />




</Routes>
    </div>
  );
}

export default App;
