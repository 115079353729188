import React, { useEffect } from 'react';
import {  FaClipboardList, FaWhatsapp, FaLeaf, FaCheckCircle, } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import './TransformationProgram.css';
import { LuHeartHandshake } from "react-icons/lu";
import { IoIosFitness } from "react-icons/io";
const TransformationProgram = () => {
  useEffect(() => {
    AOS.init(); 
  }, []);

  const programItems = [
    { text: 'Sustainable Diet Plan', icon: <LuHeartHandshake  className="program-icon green" />, description: 'A balanced, long-term nutrition plan tailored to your goals.' },
    { text: 'Workout Plan', icon: <IoIosFitness className="program-icon purple" />, description: 'Custom fitness routines to help you reach your peak performance.' },
    { text: 'Accountability', icon: <FaCheckCircle className="program-icon orange" />, description: 'Stay on track with regular check-ins and support.' },
    { text: 'Habit Tracker', icon: <FaClipboardList className="program-icon blue" />, description: 'Track your progress and build healthy habits daily.' },
    { text: '24/7 Call Support', icon: <FaWhatsapp className="program-icon green" />, description: 'Get assistance whenever you need it with 24/7 support.' }
  ];

  return (
    <div className="program-container">
      <h2 className="program-heading">
        WHAT WILL YOU GET IN THIS PROGRAM?
      </h2>
      <div className="program-box">
        {programItems.map((item, index) => (
          <div
            className="program-item"
            data-aos={index % 2 === 0 ? 'fade-up' : 'fade-down'}
            key={index}
          >
            {item.icon}
            <h3>{item.text}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransformationProgram;
