import React, { useEffect, useState, useRef } from 'react';
import './Facts.css'; // Import the CSS file for styling

const Facts = () => {
    const facts = [
        { title: 'Guaranteed change in habits which will lead to desired results', target: 100, suffix: '%' },
        { title: 'Clients trained in Bharat and globally', target: 500, suffix: '+' },
        { title: 'Followers on Instagram @dietmonetization', target: 300, suffix: 'K' },
    ];

    return (
        <div className="facts-container">
            {facts.map((fact, index) => (
                <CountdownFact key={index} title={fact.title} target={fact.target} suffix={fact.suffix} />
            ))}
        </div>
    );
};

const CountdownFact = ({ title, target, suffix }) => {
    const [count, setCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true); // Set isVisible to true when the element is in the viewport
                        observer.unobserve(ref.current); // Stop observing after it's visible
                    }
                });
            },
            { threshold: 1.0 } // Trigger when 100% of the element is in view
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current); // Cleanup observer on unmount
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            let start = 0; // Start counting from 0
            const duration = 2000; // Duration of the animation in milliseconds
            const incrementTime = duration / target; // Time per increment

            const timer = setInterval(() => {
                if (start < target) {
                    start++;
                    setCount(start);
                } else {
                    clearInterval(timer); // Clear the interval once the target is reached
                }
            }, incrementTime);

            return () => clearInterval(timer); // Cleanup function to clear the interval on component unmount
        }
    }, [isVisible, target]);

    return (
        <div className="countdown-fact" ref={ref}>
            <div className='countdown-card'>
                <h1 className="fact-number">
                    {count}{suffix} {/* Display the count with the suffix */}
                </h1>
                <h2 className="fact-title">{title}</h2>
            </div>
        </div>
    );
};

export default Facts;
