import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './ProfilePage.css';

import { FaInstagram, FaMicrophone, FaTv } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';

const ProfilePage = () => {
  useEffect(() => {
    AOS.init({ duration: 800, once: true });
  }, []);

  return (
    <div>
      <div className="profile-page">
        <div className="profile-image-container" data-aos="fade-up">
          <img src="/Image/profile.png" alt="Coach Rajan" className="profile-image" />
        </div>
        <div className="profile-content" data-aos="fade-up" data-aos-delay="200">
          <p>Hi Guys, I am <span className='coachrajan'>Coach Rajan</span> Your Last Nutritionist. I have lost 60 kgs and transformed over 1500 people.</p>
        </div>
      </div>

      <div className="achievements-container" data-aos="fade-up" data-aos-delay="400">
        <p>50 million collective views across Instagram</p>
        <p>Recognized and appreciated by many, including:</p>
        <p>
          Team RedFM, Team IshqFM 
           Iplix Media, News 18, CNN <br/>
           Ashish Chanchlani, Ankur Warikoo, and many more
        </p>
      </div>
    </div>
  );
};

export default ProfilePage;
