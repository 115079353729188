import React, { useState, useEffect } from "react";
import { textdb } from "../../firebaseConfig"; // Update with the correct path to your Firebase config
import { collection, getDocs, orderBy, query, deleteDoc, doc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./AdminPage.css";
import * as XLSX from 'xlsx';

const AdminPage = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [sortOrder, setSortOrder] = useState("new");
  const [searchTerm, setSearchTerm] = useState("");
  
  // Authentication state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      fetchEnquiries();
    }
  }, [sortOrder, isAuthenticated]);

  // Fetch and sort data based on order
  const fetchEnquiries = async () => {
    const q = query(
      collection(textdb, "enquiries"),
      orderBy("timestamp", sortOrder === "new" ? "desc" : "asc")
    );
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setEnquiries(data);
  };

  // Handle login
  const handleLogin = () => {
    if (email === "rajan@rajan.com" && password === "rajan445566") {
      setIsAuthenticated(true);
      setLoginError("");
    } else {
      setLoginError("Invalid credentials. Please try again.");
    }
  };

  // Delete document with confirmation
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this enquiry?")) {
      await deleteDoc(doc(textdb, "enquiries", id));
      toast.success("Enquiry deleted successfully!");
      fetchEnquiries();
    }
  };

  // Filtered enquiries based on search
  const filteredEnquiries = enquiries.filter((enquiry) =>
    enquiry.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Export data to Excel
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(enquiries);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Enquiries");
    XLSX.writeFile(wb, "enquiries.xlsx");
  };

  if (!isAuthenticated) {
    return (
      <div className="login-page">
        <h2>Admin Login</h2>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Login</button>
        {loginError && <p className="error">{loginError}</p>}
      </div>
    );
  }

  return (
    <div className="admin-page">
      <ToastContainer position="top-right" autoClose={3000} />
      <h2 className="admin-title">Admin Panel - Enquiries</h2>
      
      <div className="admin-actions">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        
        <select onChange={(e) => setSortOrder(e.target.value)} className="sort-select">
          <option value="new">Newest to Oldest</option>
          <option value="old">Oldest to Newest</option>
        </select>
        
        <button onClick={exportToExcel} className="export-button">
          Export to Excel
        </button>
      </div>

      <table className="enquiries-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>WhatsApp</th>
            <th>Email</th>
            <th>Age</th>
            <th>Profession</th>
            <th>Goal</th>
            <th>Timestamp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredEnquiries.length > 0 ? (
            filteredEnquiries.map((enquiry) => (
              <tr key={enquiry.id}>
                <td>{enquiry.name}</td>
                <td>{enquiry.date}</td>
                <td>{enquiry.whatsapp}</td>
                <td>{enquiry.email}</td>
                <td>{enquiry.age}</td>
                <td>{enquiry.profession}</td>
                <td>{enquiry.goal}</td>
                <td>{new Date(enquiry.timestamp?.seconds * 1000).toLocaleString()}</td>
                <td>
                  <button onClick={() => handleDelete(enquiry.id)} className="delete-button">
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">No enquiries found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPage;
