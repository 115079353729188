import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
const firebaseConfig = {

  apiKey: "AIzaSyAt2hzQrGbwXjMdPKH-GZ7zaKLFhv_v3nc",
  authDomain: "gadhganga.firebaseapp.com",
  projectId: "gadhganga",
  storageBucket: "gadhganga.firebasestorage.app",
  messagingSenderId: "138525230901",
  appId: "1:138525230901:web:a897e7e277eca736cf6e7c"
};

const app = initializeApp(firebaseConfig);
const textdb = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const database = getDatabase(app);
auth.settings.appVerificationDisabledForTesting = true;

export { textdb, storage, auth, app, database };
export default firebaseConfig;



