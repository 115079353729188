import React, { useState } from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { textdb } from "../../firebaseConfig"; // Ensure to import Firestore instance from firebaseConfig
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./ButtonPage.css";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

const ButtonPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    date: "",
    name: "",
    whatsapp: "",
    email: "",
    profession: "",
    age: "",
    weight: "",
    height: "",
    goal: "",
    investment: ""
  });

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(textdb, "enquiries"), {
        ...formData,
        timestamp: serverTimestamp(),
      });
      toast.success("Enquiry submitted successfully!");
      closeModal();
      setFormData({
        date: "",
        name: "",
        whatsapp: "",
        email: "",
        profession: "",
        age: "",
        weight: "",
        height: "",
        goal: "",
        investment: ""
      });
    } catch (error) {
      toast.error("Error adding document: " + error.message);
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="button-page">
      <ToastContainer position="top-right" autoClose={3000} />
    <Link to="https://cal.com/dietmmonetization/discovery-call">  <button  className="hero-button">Book a Free Call Now</button></Link>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
        closeTimeoutMS={300}
      >
        <button onClick={closeModal} className="close-icon"><AiOutlineClose /></button>
        <h2 className="modal-title">Your Last Enquiry Form</h2>
        <form className="enquiry-form" onSubmit={handleSubmit}>
          <label>Date *</label>
          <input type="date" name="date" value={formData.date} onChange={handleInputChange} required />

          <label>Name *</label>
          <input type="text" name="name" value={formData.name} onChange={handleInputChange} required />

          <label>Whatsapp No. *</label>
          <input type="tel" name="whatsapp" value={formData.whatsapp} onChange={handleInputChange} required />

          <label>Email *</label>
          <input type="email" name="email" value={formData.email} onChange={handleInputChange} required />

          <label>Profession *</label>
          <select name="profession" value={formData.profession} onChange={handleInputChange} required>
            <option value="">Select Profession</option>
            <option value="job">Job</option>
            <option value="self-employed">Self Employed</option>
            <option value="business">Business</option>
            <option value="housewife">Housewife</option>
            <option value="others">Others</option>
          </select>

          <label>Age *</label>
          <input type="number" name="age" min="0" value={formData.age} onChange={handleInputChange} required />

          <label>Weight *</label>
          <input type="number" name="weight" value={formData.weight} onChange={handleInputChange} required />

          <label>Height *</label>
          <input type="number" name="height" value={formData.height} onChange={handleInputChange} required />

          <label>What's your goal and biggest challenge? *</label>
          <textarea name="goal" value={formData.goal} onChange={handleInputChange} required></textarea>

          <label>Are you ready to invest 8000 per month to achieve your dream goal? *</label>
          <select name="investment" value={formData.investment} onChange={handleInputChange} required>
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>

          <div className="form-actions">
            <button type="submit" className="submit-button">Submit</button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ButtonPage;
