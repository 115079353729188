import React from 'react'
import HeroSection from '../HeroSection/HeroSection'
import TransformationProgram from '../TransformationProgram/TransformationProgram'
import HowItWorks from '../HeroSection/HowItWorks/HowItWorks'
import ImageCarousel from '../ImageCarousel/ImageCarousel'
import ImageCarouselT from '../ImageCarousel/ImageCarouselT'
import ProfilePage from '../profile/ProfilePage'
import Facts from '../Fact/Facts'
import FAQ from '../FAQItem/FAQItem'
import LogoMarqueeSection from '../LogoMarqueeSection/LogoMarqueeSection'
import AppreciationPage from '../AppreciationPage/AppreciationPage'
import Footer from '../Footer/Footer'
import ButtonPage from '../ButtonPage/ButtonPage'

const Home = () => {
  return (
    <div> 
        <HeroSection/>
        <ProfilePage/>
        <LogoMarqueeSection/>
        <AppreciationPage/>
        <ImageCarousel/>
        <TransformationProgram/>
        <ImageCarouselT/>
      
        <Facts/>
        <FAQ/>
        <ButtonPage/>
        <Footer/>
    </div>
  )
}

export default Home